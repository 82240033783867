import Vue from 'vue'
import Vuex from 'vuex'
import { info } from "@/api/api.js";
Vue.use(Vuex)

export default new Vuex.Store({
  // 全局数据
  state: {
    userinfo: {},//基本数据
    subject_id: '',//课程id
    exam_id: '',//试卷id
    class_term_id: '',//学期-班级关联表id
  },
  getters: {
  },
  mutations: {
    //基本数据 
    setusreinfo(state, params) {
      state.userinfo = params.data.data
    },
    // 试卷id
    getexam(state, params) {
      state.exam_id = params
      console.log( state.exam_id,' state.exam_id');
    }
  },
  // 异步
  actions: {
    //基本数据
    async getinfo(store) {
      let res = await info()
      store.commit('setusreinfo', res)
    },
    // 课程ad

  },
  // 模块
  modules: {
  }
})
