import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err);
};
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Login
  }, {
    path: '/login',
    component: Login
  },
  {
    path: '/loginAdmin',
    name: "loginAdmin",
    component: () => import('@/views/loginAdmin.vue')
  },
  {
    path: '/videohome',
    name: "videohome",
    component: () => import('@/views/videohome.vue')
  }, {
    path: '/workanswer',
    name: "workanswer",
    component: () => import('@/views/Workanswer.vue')
  }, {
    path: '/Workanalyze',
    name: "workanalyze",
    component: () => import('@/views/Workanalyze.vue')
  }, {
    path: '/notes',
    component: () => import('@/components/examination/notes.vue')
  }, {
    path: '/demon',
    component: () => import('@/views/demon.vue')
  }, {
    path: '/verify',
    component: () => import('@/components/examination/login.vue')
  }, {
    path: '/examination',
    component: () => import('@/components/examination/exampage.vue')
  }, {
    path: '/home',
    name: '首页',
    iconClass: 'fa fa-users',
    redirect: '/home/Learningcenter',
    component: () => import('@/views/home'),
    children: [
      {
        path: '/home/learningcenter',
        name: '学习中心',
        iconClass: 'fa fa-list',
        component: () => import('@/components/Learningcenter')
      },
      {
        path: '/home/schoolroll',
        name: '学籍',
        iconClass: 'fa fa-list-alt',
        component: () => import('@/components/schoolroll')
      },
      {
        path: '/home/exam',
        name: '考试',
        iconClass: 'fa fa-list-alt',
        component: () => import('@/components/exam')
      },
      {
        path: '/home/grade',
        name: '成绩',
        iconClass: 'fa fa-list-ul',
        component: () => import('@/components/grade')
      },
      {
        path: '/home/jop',
        name: '作业',
        iconClass: 'fa fa-th-list',
        component: () => import('@/components/jop'),
        children: [
          {
            path: '/home/jop/Offlineanswer',
            name: '作业',
            iconClass: 'fa fa-list',
            component: () => import('@/components/Offlineanswer.vue')
          }, 
          // {
          //   path: '/home/jop/Details',
          //   name: '作业',
          //   iconClass: 'fa fa-list',
          //   component: () => import('@/components/Details.vue')
          // }
        ]
      },
      {
        path: '/home/thesis',
        name: '论文',
        iconClass: 'fa fa-th-list',
        // redirect: '/home/thesis/index',
        component: () => import('@/components/thesis'),
        children: [
          // {
          //   path: '/home/thesis/index',
          //   name: '论文首页',
          //   component: () => import('@/components/thesis/index')
          // },
          {
            path: '/home/thesis/proposalreport',
            name: '开题报告上传',
            component: () => import('@/components/thesis/proposalreport')
          }, 
          {
            path: '/home/thesis/paperupload',
            name: '论文上传',
            component: () => import('@/components/thesis/paperupload')
          },
          {
            path: '/home/thesis/Paperreview',
            name: '论文上传及审核记录',
            component: () => import('@/components/thesis/Paperreview')
          },
          {
            path: '/home/thesis/Auditopinion',
            name: '审核意见',
            component: () => import('@/components/thesis/Auditopinion')
          },
          {
            path: '/home/thesis/duplicate',
            name: '查重报告',
            component: () => import('@/components/thesis/Duplicate')
          },
        ]
      },
      {
        path: '/home/finance',
        name: '财务',
        iconClass: 'fa fa-th-list',
        component: () => import('@/components/finance')
      },
      {
        path: '/home/information',
        name: '个人信息',
        iconClass: 'fa fa-th-list',
        component: () => import('@/components/information')
      },
      {
        path: '/home/settings',
        name: '设置',
        iconClass: 'fa fa-th-list',
        component: () => import('@/components/settings')
      },
      {
        path: '/home/systemmessage',
        name: '系统消息',
        iconClass: 'fa fa-th-list',
        component: () => import('@/components/systemmessage')
      }
    ]
  },


]

const router = new VueRouter({
  routes
})

export default router
