<template>
  <div id="app">
    <!-- <login></login> -->
    <router-view></router-view>
  
  </div>
</template>

<script>
// import login from "@/views/login.vue";

export default {
  components: {
    // login,  
  },

  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
*{
 margin: 0;
 padding: 0;
}
</style>
