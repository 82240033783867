import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios'
// import service from './service'
import 'default-passive-events'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// import 'default-passive-events'//阻止'touchstart'事件

import VuevideoPlayer from 'vue-video-player';

import 'video.js/dist/video-js.css';
Vue.prototype.axios = axios 

Vue.use(VuevideoPlayer);

Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
