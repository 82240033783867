import axios from 'axios'
import { getToken } from '@/utilss/setToken.js'
import { Message } from 'element-ui'
import router from '@/router'
import ElementUI from 'element-ui';
const service = axios.create({
    // baseURL: '/api',
    // baseURL: '/api',
    baseURL: 'https://adminapi.zhongrunjy.com/api',//民族大学
    timeout: 6000
})

// 添加请求拦截器
service.interceptors.request.use((config) => {
    // 在请求之前做些什么(获取并设置token)
    config.headers['token'] = getToken('token')
    return config
}, (error) => {
    return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
    // console.log(response.data.code, '拦截器');
    let { msg } = response.data

    if (response.status !== 200) {
        Message({ msg: msg || 'error', type: 'warning' })
    } else if ( response.data && response.data.code == 201) {
        router.push('/home/information')
    } 
    if(response.data.code == 401 || response.data.code == 402) {
        //删除token
        localStorage.removeItem('token')

        router.push('/')

    }


    return response
}, (error) => {
    // return Promise.reject(error)
})

export default service